import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { MdSettings, MdClose } from 'react-icons/md';

import { ColorModeSwitcher } from '../ColorModeSwitcher';

const Header = ({ heading }) => {
  let history = useHistory();
  let location = useLocation();
  console.log({ location });

  return (
    <React.Fragment>
      <Router>
        <Box height='40px'>
          <Heading
            as='h1'
            size='lg'
            fontWeight='semibold'
            userSelect='none'
            onClick={() => history.push('/')}>
            {heading}
          </Heading>
        </Box>
      </Router>
      <Box>
        {location.pathname !== '/settings' ? (
          <Icon
            as={MdSettings}
            w={5}
            h={5}
            onClick={() => history.push('/settings')}
            cursor='pointer'
            justifySelf='flex-end'
            _focus={{ outline: 'none' }}
            _hover={{ background: 'none' }}
            _active={{ background: 'none' }}
          />
        ) : (
          <Icon
            as={MdClose}
            mt='0.5'
            w={6}
            h={6}
            onClick={() => history.goBack()}
            cursor='pointer'
            justifySelf='flex-end'
            _focus={{ outline: 'none' }}
            _hover={{ background: 'none' }}
            _active={{ background: 'none' }}
          />
        )}
        <ColorModeSwitcher
          justifySelf='flex-end'
          _focus={{ outline: 'none' }}
          _hover={{ background: 'none' }}
          _active={{ background: 'none' }}
        />
      </Box>
    </React.Fragment>
  );
};

export default Header;
