import React, { useState, useEffect } from 'react';
import { Box, Center, useColorMode } from '@chakra-ui/react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../../styles.css';

const tabVariant = {
  active: {
    width: '90%',
    transition: {
      type: 'tween',
      duration: 0.4,
    },
  },
  inactive: {
    width: '10%',
    transition: {
      type: 'tween',
      duration: 0.4,
    },
  },
};

const tabTextVariant = {
  active: {
    opacity: 1,
    x: 0,
    display: 'block',
    transition: {
      type: 'tween',
      duration: 0.3,
      delay: 0.3,
    },
  },
  inactive: {
    opacity: 0,
    x: -30,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
    transitionEnd: { display: 'none' },
  },
};
const TabComponent = ({ tabs, defaultIndex = 0 }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);
  const { colorMode } = useColorMode();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--active-color',
      tabs[activeTabIndex].color
    );
  }, [activeTabIndex, tabs]);

  // Default to a tab based on the URL hash value
  useEffect(() => {
    const tabFromHash = tabs.findIndex(
      (tab) => `${tab.path}` === window.location.pathname
    );
    setActiveTabIndex(tabFromHash !== -1 ? tabFromHash : defaultIndex);
  }, [tabs, defaultIndex]);

  const onTabClick = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <Center>
      <Box
        position='fixed'
        maxWidth='800px'
        width='100%'
        bottom='0'
        overflow='hidden'
        background={colorMode === 'dark' ? 'gray.800' : 'whiteAlpha.900'}
        padding='6px'
        borderTopLeftRadius='20px'
        borderTopRightRadius='20px'>
        <ul className='tab-links' role='tablist'>
          {tabs.map((tab, index) => (
            <motion.li
              key={tab.id}
              className={cn('tab', { active: activeTabIndex === index })}
              role='presentation'
              variants={tabVariant}
              animate={activeTabIndex === index ? 'active' : 'inactive'}>
              <Link to={tab.path} onClick={() => onTabClick(index)}>
                {tab.icon}
                <motion.span variants={tabTextVariant}>{tab.title}</motion.span>
              </Link>
            </motion.li>
          ))}
        </ul>
      </Box>
    </Center>
  );
};

export default TabComponent;
