import axios from 'axios';
import { coinGeckoApiUrl, topCoinsApiUrl } from './helper';

export const coinGeckoApi = axios.create({
  baseURL: coinGeckoApiUrl,
});

export const topCoinsApi = axios.create({
  baseURL: topCoinsApiUrl,
});
