import React from 'react';

const HomePageImage = ({ className, color }) => {
  return (
    <svg
      width='301'
      height='278'
      viewBox='0 0 301 278'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 134H11' stroke='#EC4646' strokeLinecap='round' />
      <path d='M181 277H191' stroke='#EC4646' strokeLinecap='round' />
      <path d='M290 80H300' stroke='#EC4646' strokeLinecap='round' />
      <path d='M127 1H137' stroke='#EC4646' strokeLinecap='round' />
      <g opacity='0.2'>
        <rect x='31' y='37' width='210' height='50' rx='8' fill='#2C3134' />
        <circle cx='56' cy='62' r='13' fill='white' />
        <path
          d='M81 53H151'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M81 71H221'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <rect x='71' y='122' width='210' height='50' rx='8' fill='#2C3134' />
        <circle cx='96' cy='147' r='13' fill='white' />
        <path
          d='M121 138H191'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M121 156H261'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <rect x='31' y='207' width='210' height='50' rx='8' fill='#2C3134' />
        <circle cx='56' cy='232' r='13' fill='white' />
        <path
          d='M81 223H151'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M81 241H221'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
      <g filter='url(#filter0_d)'>
        <circle cx='153' cy='144' r='40' fill='#EC4646' />
        <circle cx='153' cy='144' r='35' fill='#FBFBFB' />
        <path
          d='M189 180L199 190'
          stroke='#EC4646'
          strokeWidth='4'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='103'
          y='99'
          width='108'
          height='108'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='5' />
          <feGaussianBlur stdDeviation='5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.92549 0 0 0 0 0.27451 0 0 0 0 0.27451 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HomePageImage;
