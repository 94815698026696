import React from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Center,
  Button,
  Link,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { useHistory, Link as RLink } from 'react-router-dom';

import LogoIcon from '../../assets/images/LogoIcon';
import LogoIconWhite from '../../assets/images/LogoIconWhite';

const SplashScreen = () => {
  let history = useHistory();
  const { colorMode } = useColorMode();
  React.useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return (
    <React.Fragment>
      <Container
        px='5'
        height='calc(var(--vh, 1vh) * 100 )'
        display='flex'
        justifyContent='center'
        alignItems='center'>
        <SimpleGrid column={1} mb='140px'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='85vh'>
            {colorMode === 'dark' ? <LogoIcon /> : <LogoIconWhite />}
          </Box>
          <Box>
            <Center>
              <Button
                size='md'
                width='245px'
                background='red.500'
                fontWeight='300'
                color='white'
                colorScheme='red'
                boxShadow='sm'
                onClick={() => history.push('/')}
                _active={{ background: 'none' }}>
                Continue
              </Button>
            </Center>
          </Box>
          <Box mt='3'>
            <Center>
              <Link as={RLink} to='/restore'>
                <Text fontSize='14px'>Restore</Text>
              </Link>
            </Center>
          </Box>
        </SimpleGrid>
      </Container>
    </React.Fragment>
  );
};

export default SplashScreen;
