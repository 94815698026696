import { all, takeEvery, put } from 'redux-saga/effects';

import { EMAIL } from '../action/types';
import {
  subscribeSuccess,
  subscribeFailure,
  successToast,
  errorToast,
} from '../action';
import { topCoinsApi } from '../../api';

function* watchSubscribeEmailAPI(action) {
  try {
    console.log({ action });
    yield topCoinsApi.put(`/mail`, {
      email: action.payload,
    });

    yield put(subscribeSuccess());
    yield put(successToast('Thank you for subscribing'));
  } catch (error) {
    yield put(subscribeFailure());
    yield put(errorToast('Internal Server Error'));
  }
}

export function* watchSubscribeEmail() {
  yield takeEvery(EMAIL, watchSubscribeEmailAPI);
}

export default function* rootSaga() {
  yield all([watchSubscribeEmail()]);
}
