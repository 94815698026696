import { all, takeEvery, put, call } from 'redux-saga/effects';

import {
  ADD_USER_COIN,
  STORE_COIN_LIST,
  GET_GLOBAL_RANKING_LIST,
  RESTORE_TOKEN,
  REFRESH_GLOBAL_RANKING_LIST,
} from '../action/types';
import {
  addCoinSuccess,
  addCoinFailure,
  searching,
  storeUserCoinListSuccess,
  storeUserCoinListFailure,
  getGlobalRankingListSuccess,
  getGlobalRankingListFailure,
  restoreTokenFailure,
  restoreTokenSuccess,
  successToast,
  errorToast,
} from '../action';
import { coinGeckoApi, topCoinsApi } from '../../api';
import { getToken } from '../../helper';

function* addCoinToUserListRequest(action) {
  try {
    const id = action.payload.id;
    const res = yield coinGeckoApi.get(
      `/coins/markets?vs_currency=usd&ids=${id}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
    );
    yield put(addCoinSuccess(res.data[0]));
    yield put(searching(''));
  } catch (error) {
    console.log({ error });
    yield put(addCoinFailure({}));
    yield put(searching(''));
  }
}

function* storeUserCoinsListRequest(action) {
  try {
    const res = yield topCoinsApi.post(`/updateRanking`, {
      data: action.payload.rankWithIndex,
      userToken: getToken(),
    });

    if (res.data.meta.success) {
      yield put(storeUserCoinListSuccess());
      yield call(action.payload.callback);
      yield put(successToast(res.data.meta.message));
    } else {
      yield put(errorToast(res.data.meta.message));
      yield put(storeUserCoinListFailure());
    }
  } catch (error) {
    console.log({ error });
    yield put(storeUserCoinListFailure());
    yield put(errorToast('Something went wrong.'));
  }
}

function* getUserRankingListRequest(action) {
  try {
    const res = yield topCoinsApi.get(
      `/getGlobalRanking?page=${action.payload}`
    );
    if (res.data.meta.success) {
      yield put(getGlobalRankingListSuccess(res.data));
    } else {
      yield put(getGlobalRankingListSuccess([]));
      yield put(errorToast(res.data.meta.message));
    }
  } catch (error) {
    console.log({ error });
    yield put(getGlobalRankingListFailure([]));
    yield put(errorToast('Something went wrong.'));
  }
}

function* refreshUserRankingListRequest(action) {
  try {
    const res = yield topCoinsApi.get(
      `/getGlobalRanking?page=${action.payload}`
    );
    if (res.data.meta.success) {
      yield put(getGlobalRankingListSuccess(res.data));
    } else {
      yield put(getGlobalRankingListSuccess([]));
      yield put(errorToast(res.data.meta.message));
    }
  } catch (error) {
    console.log({ error });
    yield put(getGlobalRankingListFailure([]));
    yield put(errorToast('Something went wrong.'));
  }
}

function* getrestoreAccountRequest(action) {
  try {
    const res = yield topCoinsApi.post(`/restore`, { token: action.payload });
    if (res.data.meta.success) {
      yield put(restoreTokenSuccess(res.data.data));
      yield put(successToast(res.data.meta.message));
    } else {
      yield put(restoreTokenFailure(res.data.data));
      yield put(errorToast(res.data.meta.message));
    }
  } catch (error) {
    console.log({ error });
    yield put(restoreTokenFailure());
    yield put(errorToast('Something went wrong.'));
  }
}

export function* watchAddToRankList() {
  yield takeEvery(ADD_USER_COIN, addCoinToUserListRequest);
}

export function* watchStoreRankList() {
  yield takeEvery(STORE_COIN_LIST, storeUserCoinsListRequest);
}

export function* watchGlobalRankList() {
  yield takeEvery(GET_GLOBAL_RANKING_LIST, getUserRankingListRequest);
}

export function* watchRefreshGlobalRankList() {
  yield takeEvery(REFRESH_GLOBAL_RANKING_LIST, refreshUserRankingListRequest);
}

export function* watchRestoreAccount() {
  yield takeEvery(RESTORE_TOKEN, getrestoreAccountRequest);
}

export default function* rootSaga() {
  yield all([
    watchAddToRankList(),
    watchStoreRankList(),
    watchGlobalRankList(),
    watchRestoreAccount(),
    watchRefreshGlobalRankList(),
  ]);
}
