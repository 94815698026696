import {
  getUserCoin,
  setUserCoin,
  setToken,
  removeToken,
  removeUserCoin,
} from '../../helper';
import {
  ADD_TO_COIN_LIST,
  GET_USER_COIN_LIST,
  GET_USER_COIN_LIST_FAILURE,
  GET_USER_COIN_LIST_SUCCESS,
  REODER_USER_COIN_LIST,
  DELETE_USER_COIN_LIST,
  ADD_USER_COIN,
  ADD_USER_COIN_SUCCESS,
  ADD_USER_COIN_FAILURE,
  STORE_COIN_LIST,
  STORE_COIN_LIST_SUCCESS,
  STORE_COIN_LIST_FAILURE,
  UPDATE_COIN_LIST,
  GET_GLOBAL_RANKING_LIST,
  GET_GLOBAL_RANKING_LIST_SUCCESS,
  GET_GLOBAL_RANKING_LIST_FAILURE,
  RESTORE_TOKEN,
  RESTORE_TOKEN_SUCCESS,
  RESTORE_TOKEN_FAILURE,
  REFRESH_GLOBAL_RANKING_LIST,
  REFRESH_GLOBAL_RANKING_LIST_SUCCESS,
  REFRESH_GLOBAL_RANKING_LIST_FAILURE,
} from '../action/types';

const INIT_STATE = {
  loading: false,
  userCoinsList: [],
  userCoins: [],
  userCoin: {},
  globalRankingList: [],
  restoring: false,
  redirect: false,
  isError: false,
  total: 0,
  perPage: 0,
  update: false,
  isSubmit: false,
};

const rankListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_TO_COIN_LIST:
      let coin = action.payload;
      return { ...state, userCoins: state.userCoins.concat(coin) };
    case ADD_USER_COIN:
      return { ...state, loading: true, userCoin: action.payload };
    case ADD_USER_COIN_SUCCESS:
      const coinsList = JSON.parse(getUserCoin());
      coinsList.push(action.payload);
      const coinsWithIndex = coinsList.map((val, index) => ({
        ...val,
        rank: index + 1,
      }));
      setUserCoin(JSON.stringify(coinsWithIndex));
      return {
        ...state,
        loading: false,
        userCoinsList: state.userCoinsList.concat(action.payload),
      };
    case ADD_USER_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        userCoinsList: state.userCoinsList.concat(action.payload),
      };

    case GET_USER_COIN_LIST:
      const userCoinsList = JSON.parse(getUserCoin());
      return { ...state, loading: false, userCoinsList: userCoinsList };
    case GET_USER_COIN_LIST_SUCCESS:
      return { ...state, loading: false, userCoinsList: action.payload };
    case GET_USER_COIN_LIST_FAILURE:
      return { ...state, loading: false, userCoinsList: action.payload };

    case REODER_USER_COIN_LIST:
      setUserCoin(JSON.stringify(action.payload));
      return { ...state, loading: false, userCoinsList: action.payload };
    case DELETE_USER_COIN_LIST:
      let ids = new Set(action.payload.map(({ id }) => id));
      let selectedRows = state.userCoinsList.filter(({ id }) => !ids.has(id));
      const rankWithIndex = selectedRows.map((val, index) => ({
        ...val,
        rank: index + 1,
      }));
      setUserCoin(JSON.stringify(rankWithIndex));
      return { ...state, loading: false, userCoinsList: rankWithIndex };

    case STORE_COIN_LIST:
      // console.log({ payload: action.payload });
      return {
        ...state,
        isSubmit: true,
        userCoinsList: action.payload.rankWithIndex,
      };
    case STORE_COIN_LIST_SUCCESS:
      return { ...state, isSubmit: false };
    case STORE_COIN_LIST_FAILURE:
      return { ...state, isSubmit: false };

    case GET_GLOBAL_RANKING_LIST:
      return { ...state, loading: true };
    case GET_GLOBAL_RANKING_LIST_SUCCESS:
      // console.log({ data: action.payload });
      return {
        ...state,
        loading: false,
        globalRankingList: state.globalRankingList.concat(action.payload.data),
        total: action.payload.meta.total,
        perPage: action.payload.meta.perPage,
      };
    case GET_GLOBAL_RANKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        globalRankingList: [...state.globalRankingList, action.payload],
        total: action.payload.meta.total,
        perPage: action.payload.meta.perPage,
      };
    case REFRESH_GLOBAL_RANKING_LIST:
      return { ...state, loading: true, globalRankingList: [] };
    case REFRESH_GLOBAL_RANKING_LIST_SUCCESS:
      // console.log({ data: action.payload });
      return {
        ...state,
        loading: false,
        globalRankingList: action.payload.data,
        total: action.payload.meta.total,
        perPage: action.payload.meta.perPage,
      };
    case REFRESH_GLOBAL_RANKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        globalRankingList: action.payload.data,
        total: action.payload.meta.total,
        perPage: action.payload.meta.perPage,
      };
    case RESTORE_TOKEN:
      return { ...state, restoring: true, isError: false };
    case RESTORE_TOKEN_SUCCESS:
      removeToken();
      removeUserCoin();
      setToken(action.payload.token);
      setUserCoin(JSON.stringify(action.payload.fetchUserDetails));
      return {
        ...state,
        restoring: false,
        redirect: true,
        userCoinsList: action.payload.fetchUserDetails,
      };
    case RESTORE_TOKEN_FAILURE:
      return { ...state, restoring: false, userCoinsList: [], isError: true };
    case UPDATE_COIN_LIST:
      // console.log({ payload: action.payload });
      return {
        ...state,
        update: action.payload,
      };

    default:
      return state;
  }
};

export default rankListReducer;
