import { all, takeEvery, put } from 'redux-saga/effects';

import { FETCH_COIN_LIST } from '../action/types';
import { fetchCoinListSuccess, fetchCoinListFailure } from '../action';
import { coinGeckoApi } from '../../api';

function* getFetchCoinListFromCoinGecko(action) {
  try {
    const res = yield coinGeckoApi.get(`search?locale=en`);
    yield put(fetchCoinListSuccess(res.data.coins));
  } catch (error) {
    yield put(fetchCoinListFailure(error));
  }
}

export function* watchFetchCoinList() {
  yield takeEvery(FETCH_COIN_LIST, getFetchCoinListFromCoinGecko);
}

export default function* rootSaga() {
  yield all([watchFetchCoinList()]);
}
