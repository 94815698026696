import {
  SEARCH,
  IS_DISPLAY_OPEN,
  FETCH_COIN_LIST,
  FETCH_COIN_LIST_SUCCESS,
  FETCH_COIN_LIST_FAILURE,
} from '../action/types';

const INIT_STATE = {
  search: '',
  isDisplayOpen: false,
  searchCoinList: [],
  loading: false,
};

const searchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH:
      return { ...state, search: action.payload };
    case IS_DISPLAY_OPEN:
      return { ...state, isDisplayOpen: action.payload };

    case FETCH_COIN_LIST:
      return { ...state, searchLoading: true };
    case FETCH_COIN_LIST_SUCCESS:
      return { ...state, searchLoading: false, searchCoinList: action.payload };
    case FETCH_COIN_LIST_FAILURE:
      return { ...state, searchLoading: false, searchCoinList: action.payload };

    default:
      return state;
  }
};

export default searchReducer;
