import {
  Box,
  Container,
  Spacer,
  Flex,
  Text,
  Center,
  Button,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Fade,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './styles.css';

import ModalDeleteIcon from '../../assets/images/ModalDeleteIcon';
import AddMoreIconDarkMode from '../../assets/images/AddMoreIconDarkMode';
import AddMoreIconLightMode from '../../assets/images/AddMoreIconLightMode';
import ListItems from '../ListItems';
import Search from '../Search';
import {
  deleteCoin,
  reorderUserCoinList,
  storeUserCoinList,
  isDisplayOpen as isDisplay,
  updateUserCoinList,
} from '../../utils/redux/action';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RankList = () => {
  const [edit, setEdit] = React.useState(false);
  const [coinToBeDeleted, setCoinToBeDeleted] = React.useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [minimumCoinsRequired] = React.useState(5);
  const { colorMode } = useColorMode();

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state) => state.RankList.loading);
  const update = useSelector((state) => state.RankList.update);
  const isSubmit = useSelector((state) => state.RankList.isSubmit);
  const userCoinList = useSelector((state) => state.RankList.userCoinsList);
  const isDisplayOpen = useSelector((state) => state.Search.isDisplayOpen);
  const search = useSelector((state) => state.Search.search);

  const editOn = useColorModeValue('black.500', 'white.500');
  const editOff = useColorModeValue('black.500', 'gray.500');

  const handleClick = (isEditMode, type) => {
    if (isEditMode) {
      setEdit(false);
      dispatch(reorderUserCoinList(userCoinList));
    }
    if (type === 'submit') {
      const rankWithIndex = userCoinList.map((val, index) => ({
        ...val,
        rank: index + 1,
      }));
      dispatch(
        storeUserCoinList({
          rankWithIndex,
          callback: () => {
            history.push('/global-rank-list');
            dispatch(updateUserCoinList(false));
          },
        })
      );
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = reorder(userCoinList, source.index, destination.index);
    const rankWithIndex = items.map((val, index) => ({
      ...val,
      rank: index + 1,
    }));
    dispatch(reorderUserCoinList(rankWithIndex));
    dispatch(updateUserCoinList(true));
  };

  const handleDelete = () => {
    dispatch(deleteCoin(Array(coinToBeDeleted)));
    dispatch(updateUserCoinList(true));
    onClose();
  };

  React.useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  });

  return (
    <Box height='calc(var(--vh, 1vh) * 100 - 64px)'>
      <Search />
      <Container p='2'>
        <Flex mb='0'>
          <Box my='3'>
            <Text fontSize='20px' fontWeight='500'>
              My Ranking List
            </Text>
          </Box>
          <Spacer />
          <Box
            pt='0.5'
            display='flex'
            justifyContent='center'
            alignItems='center'>
            {edit ? (
              <Text
                as='u'
                fontSize='14px'
                fontWeight='400'
                color={edit ? editOn : editOff}
                cursor='default'
                onClick={(edit) => setEdit(!edit)}
                userSelect='none'>
                Editing Ranking List
              </Text>
            ) : (
              <Text
                as='u'
                fontSize='14px'
                fontWeight='400'
                color={edit ? editOn : editOff}
                cursor='pointer'
                onClick={() => setEdit(true)}
                userSelect='none'>
                Edit Ranking List
              </Text>
            )}
          </Box>
        </Flex>
        <Box overflowX='auto'>
          <Box height='calc(var(--vh, 1vh) * 100 - 285px)' position='relative'>
            {!loading && (
              <Box
                className={
                  colorMode === 'dark' ? 'gradientbackTop' : 'gradientWhiteTop'
                }></Box>
            )}
            {loading ? (
              <Box
                height='calc(var(--vh, 1vh) * 100 - 300px)'
                display='flex'
                justifyContent='center'
                alignItems='center'>
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='orange.500'
                  size='xl'
                />
              </Box>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Column
                  coinsList={userCoinList}
                  edit={edit}
                  onOpen={onOpen}
                  setCoinToBeDeleted={setCoinToBeDeleted}
                />
                {userCoinList.length < minimumCoinsRequired && (
                  <>
                    <Box
                      onClick={() => dispatch(isDisplay(!isDisplayOpen))}
                      display='flex'
                      zIndex='1'
                      justifyContent='center'
                      alignItems='center'>
                      {colorMode === 'dark' ? (
                        <AddMoreIconDarkMode />
                      ) : (
                        <AddMoreIconLightMode />
                      )}
                    </Box>
                    <Text
                      textAlign='center'
                      mt='2'
                      fontSize='14px'
                      fontWeight='400'>
                      Add {minimumCoinsRequired - userCoinList.length} more
                      coins to access the global ranking list.
                    </Text>
                  </>
                )}
              </DragDropContext>
            )}
            {!loading && (
              <Box
                className={
                  colorMode === 'dark' ? 'gradientback' : 'gradientWhite'
                }></Box>
            )}
          </Box>
        </Box>
      </Container>
      <Box>
        {search.length > 0 || isDisplayOpen ? (
          <Center>
            <Button
              size='md'
              width='245px'
              background='red.500'
              fontWeight='300'
              color='white'
              colorScheme='red'
              position='fixed'
              boxShadow='sm'
              bottom='60px'
              zIndex='20'
              _active={{ background: 'none' }}
              onClick={() => handleClick(edit, 'addCoin')}>
              Add Coin
            </Button>
          </Center>
        ) : (
          <Box height='100vh'>
            {edit ? (
              <Center>
                <Button
                  size='md'
                  width='245px'
                  background='red.500'
                  fontWeight='300'
                  color='white'
                  colorScheme='red'
                  position='fixed'
                  boxShadow='sm'
                  bottom='60px'
                  zIndex='20'
                  _active={{ background: 'none' }}
                  onClick={() => handleClick(edit, 'save')}>
                  Save
                </Button>
              </Center>
            ) : (
              <>
                {/* <Link to='/global-rank-list'> */}
                {update ? (
                  <Center>
                    <Button
                      disabled={
                        !isSubmit
                          ? userCoinList.length >= minimumCoinsRequired
                            ? false
                            : true
                          : true
                      }
                      size='md'
                      width='245px'
                      background='red.500'
                      fontWeight='300'
                      color='white'
                      colorScheme='red'
                      position='fixed'
                      boxShadow='sm'
                      bottom='60px'
                      zIndex='20'
                      _active={{ background: 'none' }}
                      onClick={() => handleClick(edit, 'submit')}>
                      {!isSubmit ? 'Submit Ranking' : 'Submitting'}
                    </Button>
                  </Center>
                ) : (
                  <Center>
                    <Button
                      size='md'
                      width='245px'
                      background='red.500'
                      fontWeight='300'
                      color='white'
                      colorScheme='red'
                      position='fixed'
                      boxShadow='sm'
                      bottom='60px'
                      zIndex='20'
                      _active={{ background: 'none' }}
                      onClick={() => dispatch(isDisplay(!isDisplayOpen))}>
                      Add Coin
                    </Button>
                  </Center>
                )}

                {/* </Link> */}
              </>
            )}
          </Box>
        )}
      </Box>

      <ModalCompo
        onClose={onClose}
        isOpen={isOpen}
        setCoinToBeDeleted={setCoinToBeDeleted}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default RankList;

const ModalCompo = ({ onClose, isOpen, setCoinToBeDeleted, handleDelete }) => {
  return (
    <Fade in={isOpen}>
      <Modal
        onClose={onClose}
        size='xs'
        isOpen={isOpen}
        isCentered
        background='white.500'>
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            mt='4'>
            <ModalDeleteIcon />
            <Text fontSize='14px' fontWeight='400' textAlign='center' my='4'>
              Are you sure you want to delete this coins from your ranking list?
            </Text>
          </ModalBody>
          <ModalFooter
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            mx='2'>
            <Box>
              <Button
                width='110px'
                fontWeight='400'
                onClick={() => {
                  onClose();
                  setCoinToBeDeleted({});
                }}
                _focus={{ outline: 'none' }}
                variant='outline'>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                width='110px'
                background='red.500'
                fontWeight='300'
                color='white'
                colorScheme='red'
                onClick={() => handleDelete()}>
                Delete
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fade>
  );
};

const Column = ({ coinsList, edit, onOpen, setCoinToBeDeleted }) => (
  <Box mb='0'>
    <Droppable droppableId='droppable'>
      {(provided) => (
        <Box {...provided.droppableProps} ref={provided.innerRef}>
          {coinsList.map((el, index) => (
            <List
              el={el}
              index={index}
              edit={edit}
              onOpen={onOpen}
              key={index}
              setCoinToBeDeleted={setCoinToBeDeleted}
            />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  </Box>
);

const List = ({ index, el, edit, onOpen, setCoinToBeDeleted }) => {
  return (
    <>
      {edit ? (
        <Draggable draggableId={el.id} index={index} key={el.id}>
          {(provided) => (
            <Flex {...provided.draggableProps} ref={provided.innerRef}>
              <ListItems
                el={el}
                index={index}
                edit={edit}
                onOpen={onOpen}
                provided={provided}
                setCoinToBeDeleted={setCoinToBeDeleted}
              />
            </Flex>
          )}
        </Draggable>
      ) : (
        <ListItems
          el={el}
          index={index}
          edit={edit}
          onOpen={onOpen}
          setCoinToBeDeleted={setCoinToBeDeleted}
        />
      )}
    </>
  );
};
