import React from 'react';

const MoveButtonIcon = ({ className, color }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='1' cy='1' r='1' fill='white' />
      <circle cx='1' cy='6' r='1' fill='white' />
      <circle cx='1' cy='11' r='1' fill='white' />
      <circle cx='6' cy='1' r='1' fill='white' />
      <circle cx='11' cy='1' r='1' fill='white' />
      <circle cx='6' cy='6' r='1' fill='white' />
      <circle cx='11' cy='6' r='1' fill='white' />
      <circle cx='6' cy='11' r='1' fill='white' />
      <circle cx='11' cy='11' r='1' fill='white' />
    </svg>
  );
};

export default MoveButtonIcon;
