import React from 'react';

const Logo = ({ className, color }) => {
  return (
    <svg
      width='188'
      height='119'
      viewBox='0 0 188 119'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='144'
        cy='74'
        r='42'
        fill='#FAA300'
        stroke='#1F2326'
        strokeWidth='4'
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='104'
        y='34'
        width='80'
        height='80'>
        <circle cx='144' cy='74' r='40' fill='#FAA300' />
      </mask>
      <g mask='url(#mask0)'>
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M140 115V33H148V115H140Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M103 70L185 70L185 78L103 78L103 70Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M112.373 100.356L170.356 42.3728L175.627 47.644L117.644 105.627L112.373 100.356Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M117.644 42.373L175.627 100.356L170.356 105.627L112.373 47.6441L117.644 42.373Z'
          fill='#D9291A'
        />
      </g>
      <g filter='url(#filter0_d)'>
        <circle cx='144' cy='74' r='31' fill='#FFF3C1' />
      </g>
      <g filter='url(#filter1_d)'>
        <circle cx='144' cy='74' r='27' stroke='#472F03' />
      </g>
      <g filter='url(#filter2_d)'>
        <circle
          cx='144'
          cy='74'
          r='36'
          stroke='#472F03'
          strokeDasharray='5 5 5 5'
        />
      </g>
      <path
        d='M144 88C150.692 88 155 84.5907 155 79.7504C155 75.5414 151.319 73.3106 148.475 73.016C151.57 72.4688 154.582 70.1538 154.582 66.6183C154.582 61.9463 150.567 59 144.042 59C139.148 59 135.635 60.8941 133.376 63.4615L136.346 67.2075C138.312 65.3135 140.738 64.3033 143.456 64.3033C146.342 64.3033 148.643 65.3977 148.643 67.6284C148.643 69.6909 146.551 70.6168 143.456 70.6168C142.411 70.6168 140.487 70.6168 139.985 70.5747V75.9623C140.403 75.9202 142.285 75.8781 143.456 75.8781C147.346 75.8781 149.061 76.8882 149.061 79.119C149.061 81.2235 147.179 82.6967 143.791 82.6967C141.072 82.6967 138.019 81.5181 136.137 79.4978L133 83.4964C135.049 86.0218 138.897 88 144 88Z'
        fill='#472F03'
      />
      <circle
        cx='44'
        cy='74'
        r='42'
        fill='#FAA300'
        stroke='#1F2326'
        strokeWidth='4'
      />
      <mask
        id='mask1'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='4'
        y='34'
        width='80'
        height='80'>
        <circle cx='44' cy='74' r='40' fill='#FAA300' />
      </mask>
      <g mask='url(#mask1)'>
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M40 115V33H48V115H40Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 70L85 70L85 78L3 78L3 70Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.3732 100.356L70.3559 42.3728L75.6271 47.644L17.6443 105.627L12.3732 100.356Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.6442 42.373L75.627 100.356L70.3558 105.627L12.3731 47.6441L17.6442 42.373Z'
          fill='#D9291A'
        />
      </g>
      <g filter='url(#filter3_d)'>
        <circle cx='44' cy='74' r='31' fill='#FFF3C1' />
      </g>
      <g filter='url(#filter4_d)'>
        <circle cx='44' cy='74' r='27' stroke='#472F03' />
      </g>
      <g filter='url(#filter5_d)'>
        <circle
          cx='44'
          cy='74'
          r='36'
          stroke='#472F03'
          strokeDasharray='5 5 5 5'
        />
      </g>
      <path
        d='M55 88V82.6455H43.4255C50.7872 77.2482 54.7872 73.0074 54.7872 68.1241C54.7872 62.4269 50.0213 59 43.9362 59C39.9787 59 35.766 60.4564 33 63.6691L36.4468 67.6957C38.3617 65.6824 40.8298 64.3973 44.0638 64.3973C46.4043 64.3973 48.6596 65.5968 48.6596 68.1241C48.6596 71.6366 45.2553 74.5495 33.6383 83.2024V88H55Z'
        fill='#472F03'
      />
      <circle
        cx='94'
        cy='44'
        r='42'
        fill='#FAA300'
        stroke='#1F2326'
        strokeWidth='4'
      />
      <mask
        id='mask2'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='54'
        y='4'
        width='80'
        height='80'>
        <circle cx='94' cy='44' r='40' fill='#FAA300' />
      </mask>
      <g mask='url(#mask2)'>
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M90 85V3H98V85H90Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M53 40L135 40L135 48L53 48L53 40Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M62.3732 70.3556L120.356 12.3728L125.627 17.644L67.6443 75.6267L62.3732 70.3556Z'
          fill='#D9291A'
        />
        <path
          opacity='0.4'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M67.6442 12.373L125.627 70.3557L120.356 75.6269L62.3731 17.6441L67.6442 12.373Z'
          fill='#D9291A'
        />
      </g>
      <g filter='url(#filter6_d)'>
        <circle cx='94' cy='44' r='31' fill='#FFF3C1' />
      </g>
      <g filter='url(#filter7_d)'>
        <circle cx='94' cy='44' r='27' stroke='#472F03' />
      </g>
      <g filter='url(#filter8_d)'>
        <circle
          cx='94'
          cy='44'
          r='36'
          stroke='#472F03'
          strokeDasharray='5 5 5 5'
        />
      </g>
      <path
        d='M99 58V29H93.8024L85 38.1739L88.3952 41.8696L93.0479 36.9565V58H99Z'
        fill='#472F03'
      />
      <defs>
        <filter
          id='filter0_d'
          x='109'
          y='39'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d'
          x='112.5'
          y='46.5'
          width='63'
          height='63'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter2_d'
          x='103.5'
          y='37.5'
          width='81'
          height='81'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter3_d'
          x='9'
          y='39'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter4_d'
          x='12.5'
          y='46.5'
          width='63'
          height='63'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter5_d'
          x='3.5'
          y='37.5'
          width='81'
          height='81'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter6_d'
          x='59'
          y='9'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.3 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter7_d'
          x='62.5'
          y='16.5'
          width='63'
          height='63'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='filter8_d'
          x='53.5'
          y='7.5'
          width='81'
          height='81'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Logo;
