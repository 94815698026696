import React from 'react';

const DeleteButtonIcon = ({ className, color }) => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.1185 4.51319H1.88154C1.77079 4.50365 1.66105 4.54075 1.57807 4.61574C1.49647 4.69521 1.45868 4.81024 1.47692 4.92344L2.52893 14.2154C2.6523 15.2399 3.51391 16.0076 4.5318 16H9.63006C10.6766 16.0151 11.5582 15.2107 11.6532 14.1539L12.5231 4.88241C12.5285 4.78296 12.4917 4.6859 12.422 4.61574C12.339 4.54075 12.2293 4.50365 12.1185 4.51319ZM10.844 14.1129C10.7804 14.7375 10.2489 15.2045 9.6301 15.1795H4.53184C3.92411 15.2045 3.40172 14.7467 3.33818 14.1334L2.32664 5.33368H11.6734L10.844 14.1129Z'
        fill='white'
      />
      <path
        d='M13.5954 1.84632H9.42773V1.1899C9.45019 0.555908 8.9615 0.023522 8.33618 0.000750378C8.31566 4.40953e-06 8.2951 -0.000191898 8.27457 0.000200717H5.72543C5.0998 -0.0115385 4.58331 0.493129 4.57173 1.12744C4.57134 1.14825 4.57154 1.16909 4.57227 1.1899V1.84628H0.404625C0.18115 1.84628 0 2.02994 0 2.25652C0 2.4831 0.18115 2.66676 0.404625 2.66676H13.5954C13.8188 2.66676 14 2.4831 14 2.25652C14 2.02994 13.8188 1.84632 13.5954 1.84632ZM8.61848 1.1899V1.84628H5.38148V1.1899C5.35867 1.01012 5.48394 0.845658 5.66126 0.822533C5.68252 0.819745 5.70401 0.819156 5.72539 0.820688H8.27454C8.45286 0.807888 8.60768 0.944047 8.6203 1.12489C8.62185 1.14656 8.62123 1.16835 8.61848 1.1899Z'
        fill='white'
      />
    </svg>
  );
};

export default DeleteButtonIcon;
