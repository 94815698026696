import { EMAIL, EMAIL_SUCCESS, EMAIL_FAILURE } from './types';

export const subscribe = (payload) => ({
  type: EMAIL,
  payload,
});

export const subscribeSuccess = (payload) => ({
  type: EMAIL_SUCCESS,
  payload,
});

export const subscribeFailure = (payload) => ({
  type: EMAIL_FAILURE,
  payload,
});
