import React from 'react';
import Home from '../pages/Home';

// const Home = React.lazy(() => import('../pages/Home'));
const PageNotFound = React.lazy(() => import('../pages/PageNotFound'));
const GlobalRankList = React.lazy(() => import('../pages/GlobalRankList'));
const Settings = React.lazy(() => import('../pages/Settings'));
const Restore = React.lazy(() => import('../pages/Restore'));

export const PublicRoutes = [
  {
    path: '/global-rank-list',
    component: GlobalRankList,
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
  },
  {
    path: '/restore',
    component: Restore,
    exact: true,
  },
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    component: PageNotFound,
    exact: false,
  },
];
