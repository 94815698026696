import React from 'react';
import { Helmet } from 'react-helmet';

import RankListComponent from '../../components/RankList';
import { companyName } from '../../constants';

const RankList = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{companyName} | Rank List</title>
      </Helmet>
      <RankListComponent />
    </React.Fragment>
  );
};

export default RankList;
