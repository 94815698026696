export let searchBinary = function (needle, haystack, case_insensitive) {
  if (needle === '') return [];
  let haystackLength = haystack.length;
  let letterNumber = needle.length;
  case_insensitive =
    typeof case_insensitive === 'undefined' || case_insensitive ? true : false;
  needle = case_insensitive ? needle.toLowerCase() : needle;

  /* start binary search, Get middle position */
  let getElementPosition = findElement();

  /* get interval and return result array */
  if (getElementPosition === -1) return [];
  return findRangeElement();

  function findElement() {
    if (typeof haystack === 'undefined' || !haystackLength) return -1;

    let high = haystack.length - 1;
    let low = 0;

    while (low <= high) {
      let mid = parseInt((low + high) / 2);
      let element = haystack[mid].substr(0, letterNumber);
      element = case_insensitive ? element.toLowerCase() : element;

      if (element > needle) {
        high = mid - 1;
      } else if (element < needle) {
        low = mid + 1;
      } else {
        return mid;
      }
    }
    return -1;
  }
  function findRangeElement() {
    let start, end;
    for (let i = getElementPosition; i > 0; i--) {
      let element = case_insensitive
        ? haystack[i].substr(0, letterNumber).toLowerCase()
        : haystack[i].substr(0, letterNumber);
      if (element !== needle) {
        start = i + 1;
        break;
      } else {
        start = 0;
      }
    }
    for (let i = getElementPosition; i < haystackLength; i++) {
      let element = case_insensitive
        ? haystack[i].substr(0, letterNumber).toLowerCase()
        : haystack[i].substr(0, letterNumber);
      if (element !== needle) {
        end = i;
        break;
      } else {
        end = haystackLength - 1;
      }
    }
    let result = [];
    for (let i = start; i < end; i++) {
      result.push(haystack[i]);
    }

    return result;
  }
};

export const alphabetically = (ascending) => {
  return function (a, b) {
    // equal items sort equally
    if (a.market_cap_rank === b.market_cap_rank) {
      return 0;
    }
    // nulls sort after anything else
    else if (a.market_cap_rank === null) {
      return 1;
    } else if (b.market_cap_rank === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
      return a.market_cap_rank < b.market_cap_rank ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
      return a.market_cap_rank < b.market_cap_rank ? 1 : -1;
    }
  };
};

export const coinGeckoApiUrl = process.env.REACT_APP_COINGECKO_API_ENDPOINT;

export const topCoinsApiUrl = process.env.REACT_APP_TOPCOINS_API_ENDPOINT;

export const setToken = (token) => localStorage.setItem('topCoinToken', token);

export const getToken = () => localStorage.getItem('topCoinToken');

export const removeToken = () => localStorage.removeItem('topCoinToken');

export const setUserCoin = (coin) => localStorage.setItem('coins', coin);

export const getUserCoin = () => localStorage.getItem('coins');

export const removeUserCoin = () => localStorage.removeItem('coins');

export const setIsSub = (bol) => localStorage.setItem('isSub', bol);

export const getIsSub = () => localStorage.getItem('isSub');

export const validateEmail = (email) => {
  const re =
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
