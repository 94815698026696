import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { clearToast } from '../../utils/redux/action';

const Toast = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const description = useSelector((state) => state.Toast.description);
  const status = useSelector((state) => state.Toast.status);

  React.useEffect(() => {
    if (description !== '' && status !== '') {
      toast({
        description: description,
        status: status,
        position: 'top',
        duration: 3000,
        isClosable: true,
      });
    }
    return () => {
      dispatch(clearToast());
    };
  }, [toast, description, status, dispatch]);

  return <React.Fragment></React.Fragment>;
};

export default Toast;
