import {
  Box,
  Container,
  Button,
  Center,
  Text,
  useColorMode,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import HomePageDark from "../../assets/images/HomePageDark";
import HomePageLight from "../../assets/images/HomePageLight";
import { isDisplayOpen } from "../../utils/redux/action";
import Search from "../Search";

const Home = () => {
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const displayOpen = useSelector((state) => state.Search.isDisplayOpen);
  const loading = useSelector((state) => state.RankList.loading);

  const handleClick = () => {
    dispatch(isDisplayOpen(!displayOpen));
  };

  React.useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  });

  return (
    <Box height="calc(var(--vh, 1vh) * 100 - 64px)">
      <Search />
      <Container
        px="0"
        height="calc(var(--vh, 1vh) * 100 - 64px)"
        my="10"
        centerContent
      >
        {loading ? (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="orange.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box height="100%" position="relative">
            <Box
              my="8"
              width="100vw"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {colorMode === "light" ? <HomePageLight /> : <HomePageDark />}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Text
                fontSize="14"
                textAlign="center"
                maxWidth="300px"
                fontWeight="400"
              >
                Rank your favourite coins to unlock the global ranking list.
              </Text>
            </Box>
            <Box>
              <Center>
                <Button
                  size="md"
                  width="245px"
                  background="red.500"
                  fontWeight="300"
                  position="fixed"
                  color="white"
                  colorScheme="red"
                  boxShadow="sm"
                  bottom="60px"
                  zIndex="20"
                  onClick={handleClick}
                >
                  Add Coin
                </Button>
              </Center>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Home;
