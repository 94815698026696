import React from 'react';
import { Switch } from 'react-router-dom';
import { createHash } from 'crypto';

import { getToken, setToken, setUserCoin } from '../utils';

const RootView = () => {
  React.useEffect(() => {
    const token = getToken();
    if (token) {
    } else {
      let coinList = [];
      const date = Date.now();
      const hashable = [date].join('');
      let hash = createHash('md5')
        .update(hashable)
        .digest('hex')
        .substring(0, 16);

      setToken(hash);
      setUserCoin(JSON.stringify(coinList));
    }
  }, []);

  return (
    <React.Fragment>
      <Switch />
    </React.Fragment>
  );
};

export default RootView;
