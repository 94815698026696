import {
  SUCCESS_TOAST,
  ERROR_TOAST,
  INFO_TOAST,
  WARNING_TOAST,
  CLEAR_TOAST,
} from './types';

export const successToast = (payload) => ({
  type: SUCCESS_TOAST,
  payload,
});

export const errorToast = (payload) => ({
  type: ERROR_TOAST,
  payload,
});

export const infoToast = (payload) => ({
  type: INFO_TOAST,
  payload,
});

export const warningToast = (payload) => ({
  type: WARNING_TOAST,
  payload,
});

export const clearToast = () => ({
  type: CLEAR_TOAST,
});
