import React from 'react';
import { createHash } from 'crypto';

import Layout from '../../components/Layout';
import HomeContainer from '../../containers/Home';
import Splash from '../../containers/SplashScreen';
import { getToken, setToken, setUserCoin } from '../../utils/helper';

const Home = () => {
  React.useEffect(() => {
    const token = getToken();
    if (token) {
    } else {
      let coinList = [];
      const date = Date.now();
      const hashable = [date].join('');
      let hash = createHash('md5')
        .update(hashable)
        .digest('hex')
        .substring(0, 16);

      setToken(hash);
      setUserCoin(JSON.stringify(coinList));
    }
  }, []);

  return (
    <>
      {!getToken() ? (
        <Splash />
      ) : (
        <Layout>
          <HomeContainer />
        </Layout>
      )}
    </>
  );
};

export default Home;
