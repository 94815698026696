import {
  ADD_TO_COIN_LIST,
  GET_USER_COIN_LIST,
  GET_USER_COIN_LIST_SUCCESS,
  GET_USER_COIN_LIST_FAILURE,
  REODER_USER_COIN_LIST,
  DELETE_USER_COIN_LIST,
  ADD_USER_COIN,
  ADD_USER_COIN_SUCCESS,
  ADD_USER_COIN_FAILURE,
  STORE_COIN_LIST,
  STORE_COIN_LIST_SUCCESS,
  STORE_COIN_LIST_FAILURE,
  UPDATE_COIN_LIST,
  UPDATE_COIN_LIST_SUCCESS,
  UPDATE_COIN_LIST_FAILURE,
  GET_GLOBAL_RANKING_LIST,
  GET_GLOBAL_RANKING_LIST_SUCCESS,
  GET_GLOBAL_RANKING_LIST_FAILURE,
  RESTORE_TOKEN,
  RESTORE_TOKEN_SUCCESS,
  RESTORE_TOKEN_FAILURE,
  REFRESH_GLOBAL_RANKING_LIST,
  REFRESH_GLOBAL_RANKING_LIST_SUCCESS,
  REFRESH_GLOBAL_RANKING_LIST_FAILURE,
} from './types';

export const addToCoinList = (payload) => ({
  type: ADD_TO_COIN_LIST,
  payload,
});

export const getUserCoinsList = () => ({
  type: GET_USER_COIN_LIST,
});

export const getUserCoinsListSuccess = (payload) => ({
  type: GET_USER_COIN_LIST_SUCCESS,
  payload,
});

export const getUserCoinsListFailure = (payload) => ({
  type: GET_USER_COIN_LIST_FAILURE,
  payload,
});

export const reorderUserCoinList = (payload) => ({
  type: REODER_USER_COIN_LIST,
  payload,
});

export const deleteCoin = (payload) => ({
  type: DELETE_USER_COIN_LIST,
  payload,
});

export const addCoin = (payload) => ({
  type: ADD_USER_COIN,
  payload,
});

export const addCoinSuccess = (payload) => ({
  type: ADD_USER_COIN_SUCCESS,
  payload,
});

export const addCoinFailure = (payload) => ({
  type: ADD_USER_COIN_FAILURE,
  payload,
});

export const storeUserCoinList = (payload) => ({
  type: STORE_COIN_LIST,
  payload,
});

export const storeUserCoinListSuccess = (payload) => ({
  type: STORE_COIN_LIST_SUCCESS,
  payload,
});

export const storeUserCoinListFailure = (payload) => ({
  type: STORE_COIN_LIST_FAILURE,
  payload,
});

export const getGlobalRankingList = (payload) => ({
  type: GET_GLOBAL_RANKING_LIST,
  payload,
});

export const getGlobalRankingListSuccess = (payload) => ({
  type: GET_GLOBAL_RANKING_LIST_SUCCESS,
  payload,
});

export const getGlobalRankingListFailure = (payload) => ({
  type: GET_GLOBAL_RANKING_LIST_FAILURE,
  payload,
});

export const refreshGlobalRankingList = (payload) => ({
  type: REFRESH_GLOBAL_RANKING_LIST,
  payload,
});

export const refreshGlobalRankingListSuccess = (payload) => ({
  type: REFRESH_GLOBAL_RANKING_LIST_SUCCESS,
  payload,
});

export const refreshGlobalRankingListFailure = (payload) => ({
  type: REFRESH_GLOBAL_RANKING_LIST_FAILURE,
  payload,
});

export const restoreToken = (payload) => ({
  type: RESTORE_TOKEN,
  payload,
});

export const restoreTokenSuccess = (payload) => ({
  type: RESTORE_TOKEN_SUCCESS,
  payload,
});

export const restoreTokenFailure = (payload) => ({
  type: RESTORE_TOKEN_FAILURE,
  payload,
});

export const updateUserCoinList = (payload) => ({
  type: UPDATE_COIN_LIST,
  payload,
});

export const updateUserCoinListSuccess = (payload) => ({
  type: UPDATE_COIN_LIST_SUCCESS,
  payload,
});

export const updateUserCoinListFailure = (payload) => ({
  type: UPDATE_COIN_LIST_FAILURE,
  payload,
});
