import React from 'react';

const AddMoreIconLightMode = ({ className, color }) => {
  return (
    <svg
      width='130'
      height='96'
      viewBox='0 0 130 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M107.369 29.4737H14.6311C6.56312 29.4737 0 22.8632 0 14.7368C0 6.61053 6.56312 0 14.6311 0H107.369C115.437 0 122 6.61053 122 14.7368C122 22.8632 115.437 29.4737 107.369 29.4737ZM14.6311 4.21053C8.87066 4.21053 4.18033 8.93053 4.18033 14.7368C4.18033 20.5432 8.87066 25.2632 14.6311 25.2632H107.369C113.129 25.2632 117.82 20.5432 117.82 14.7368C117.82 8.93053 113.129 4.21053 107.369 4.21053H14.6311Z'
        fill='#EC4646'
      />
      <path
        d='M69.7459 54.7369H14.6311C6.56312 54.7369 0 48.1263 0 40C0 36.0674 1.52164 32.3706 4.28902 29.5832C7.05639 26.7958 10.7309 25.2632 14.6311 25.2632H107.369C113.288 25.2632 118.576 28.7958 120.834 34.2653C121.277 35.339 120.775 36.5727 119.709 37.019C118.652 37.4569 117.418 36.96 116.975 35.8821C115.366 31.9916 111.595 29.4737 107.369 29.4737H14.6311C11.847 29.4737 9.22598 30.5727 7.24451 32.56C5.26303 34.5474 4.18033 37.1958 4.18033 40C4.18033 45.8063 8.87066 50.5263 14.6311 50.5263H69.7459C70.8997 50.5263 71.8361 51.4695 71.8361 52.6316C71.8361 53.7937 70.8997 54.7369 69.7459 54.7369Z'
        fill='#EC4646'
      />
      <path
        d='M65.5656 80H14.6311C6.56312 80 0 73.3895 0 65.2632C0 61.3306 1.52164 57.6337 4.28902 54.8464C7.05639 52.059 10.7309 50.5264 14.6311 50.5264H69.7459C70.8997 50.5264 71.8361 51.4695 71.8361 52.6316C71.8361 53.7937 70.8997 54.7369 69.7459 54.7369H14.6311C11.847 54.7369 9.22598 55.8358 7.24451 57.8232C5.26303 59.8106 4.18033 62.459 4.18033 65.2632C4.18033 71.0695 8.87066 75.7895 14.6311 75.7895H65.5656C66.7193 75.7895 67.6557 76.7327 67.6557 77.8948C67.6557 79.0569 66.7193 80 65.5656 80Z'
        fill='#EC4646'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130 69C130 83.9117 117.912 96 103 96C88.0883 96 76 83.9117 76 69C76 54.0883 88.0883 42 103 42C117.912 42 130 54.0883 130 69ZM90.1429 70C88.96 70 88 69.104 88 68C88 66.896 88.96 66 90.1429 66H101V56.1429C101 54.96 101.896 54 103 54C104.104 54 105 54.96 105 56.1429V66H115.857C117.04 66 118 66.896 118 68C118 69.104 117.04 70 115.857 70H105V81.8571C105 83.04 104.104 84 103 84C101.896 84 101 83.04 101 81.8571V70H90.1429Z'
        fill='#EC4646'
      />
    </svg>
  );
};

export default AddMoreIconLightMode;
