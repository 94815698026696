import {
  SEARCH,
  IS_DISPLAY_OPEN,
  FETCH_COIN_LIST,
  FETCH_COIN_LIST_FAILURE,
  FETCH_COIN_LIST_SUCCESS,
} from './types';

export const searching = (payload) => ({
  type: SEARCH,
  payload,
});

export const isDisplayOpen = (payload) => ({
  type: IS_DISPLAY_OPEN,
  payload,
});

export const fetchCoinList = (payload) => ({
  type: FETCH_COIN_LIST,
  payload,
});

export const fetchCoinListSuccess = (payload) => ({
  type: FETCH_COIN_LIST_SUCCESS,
  payload,
});

export const fetchCoinListFailure = (payload) => ({
  type: FETCH_COIN_LIST_FAILURE,
  payload,
});
