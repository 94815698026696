import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Center,
  Stack,
  useColorModeValue,
  Spacer,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import DeleteButtonIconBlack from '../../assets/images/DeleteButtonIconBlack';
import DeleteButtonIconWhite from '../../assets/images/DeleteButtonIconWhite';
import MoveButtonIconWhite from '../../assets/images/MoveButtonIconWhite';
import MoveButtonIconBlack from '../../assets/images/MoveButtonIconBlack';

const ListItems = ({
  index,
  el,
  edit,
  onOpen,
  provided,
  setCoinToBeDeleted,
}) => {
  const { colorMode } = useColorMode();

  const listBg = useColorModeValue('whiteAlpha.900', 'gray.800');
  const coinBg = useColorModeValue('#F8F8F8', '#373738');
  const closeColor = useColorModeValue('black.500', 'white.500');

  return (
    <Box width='100%' background={listBg} borderRadius='md' pb='2'>
      <SimpleGrid
        columns={edit ? '3' : '2'}
        display='flex'
        justifyContent='space-between'>
        <Flex minWidth='190px' justify='flex-start'>
          <Box py='4'>
            <Text fontSize='14' fontWeight='300'>
              {(index + 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </Text>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            px='3'>
            <Box
              borderRadius='50%'
              width='50px'
              height='50px'
              background={coinBg}
              display='flex'
              justifyContent='center'
              alignItems='center'>
              <LazyLoadImage
                style={{
                  borderRadius: '50%',
                }}
                alt={el.image}
                height={35}
                src={el.image} // use normal <img> attributes as props
                width={35}
                effect='black-and-white'
              />
            </Box>
          </Box>
          <Stack>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='flex-start'
              pt='2'>
              <Text
                fontSize='15px'
                fontWeight='400'
                lineHeight='17px'
                textTransform='capitalize'
                paddingRight='2px'>
                {el.name}
              </Text>

              <Text
                fontSize='12px'
                fontWeight='300'
                textTransform='uppercase'
                ml='1'>
                ({el.symbol})
              </Text>
            </Box>
            <Box>
              <Text fontSize='14px' fontWeight='400' letterSpacing='wide'>
                ${Number(el.current_price).toFixed(2)}
              </Text>
            </Box>
          </Stack>
        </Flex>
        <Spacer />
        <Flex align='flex-end' pb='1' pr='1'>
          <Stack>
            <Text fontSize='12' fontWeight='300' align='end'>
              {el.market_cap_rank
                ? `#${el.market_cap_rank.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}`
                : 'NA'}
            </Text>
            <Text
              fontSize='11px'
              fontWeight='300'
              letterSpacing='wide'
              color={
                Math.sign(el.price_change_percentage_24h) === 1 ||
                Math.sign(el.price_change_percentage_24h) === 0
                  ? 'green.500'
                  : 'red.500'
              }
              textAlign='end'>
              {Number(el.price_change_percentage_24h).toFixed(2)}%
            </Text>
          </Stack>
        </Flex>

        {edit && (
          <Flex justify='flex-end'>
            <Flex justify='center' align='center' pl='2'>
              <Box
                borderRadius='50%'
                width='40px'
                height='40px'
                background={coinBg}
                display='flex'
                justifyContent='center'
                alignItems='center'
                cursor='pointer'
                pl='0.6'
                userSelect='none'
                onClick={() => {
                  setCoinToBeDeleted(el);
                  onOpen();
                }}>
                {colorMode !== 'light' ? (
                  <DeleteButtonIconWhite />
                ) : (
                  <DeleteButtonIconBlack />
                )}
              </Box>
            </Flex>
            <Flex justify='center' align='center'>
              <Box
                borderRadius='50%'
                width='40px'
                height='40px'
                background={coinBg}
                display='flex'
                justifyContent='center'
                alignItems='center'
                ml='2'
                {...provided.dragHandleProps}>
                {colorMode !== 'light' ? (
                  <MoveButtonIconWhite />
                ) : (
                  <MoveButtonIconBlack />
                )}
              </Box>
            </Flex>
          </Flex>
        )}
      </SimpleGrid>

      <Center>
        <Box
          width='94%'
          mt='2'
          borderTopColor={closeColor}
          borderTopStyle='solid'
          borderTopWidth='1px'
        />
      </Center>
    </Box>
  );
};

export default ListItems;
