export const ADD_TO_COIN_LIST = 'ADD_TO_COIN_LIST';
export const ADD_USER_COIN = 'ADD_USER_COIN';
export const ADD_USER_COIN_SUCCESS = 'ADD_USER_COIN_SUCCESS';
export const ADD_USER_COIN_FAILURE = 'ADD_USER_COIN_FAILURE';

export const GET_USER_COIN_LIST = 'GET_USER_COIN_LIST';
export const GET_USER_COIN_LIST_SUCCESS = 'GET_USER_COIN_LIST_SUCCESS';
export const GET_USER_COIN_LIST_FAILURE = 'GET_USER_COIN_LIST_FAILURE';

export const REODER_USER_COIN_LIST = 'REODER_USER_COIN_LIST';
export const DELETE_USER_COIN_LIST = 'DELETE_USER_COIN_LIST';

export const SEARCH = 'SEARCH';
export const IS_DISPLAY_OPEN = 'IS_DISPLAY_OPEN';

export const FETCH_COIN_LIST = 'FETCH_COIN_LIST';
export const FETCH_COIN_LIST_SUCCESS = 'FETCH_COIN_LIST_SUCCESS';
export const FETCH_COIN_LIST_FAILURE = 'FETCH_COIN_LIST_FAILURE';

export const UPDATE_COIN_LIST = 'UPDATE_COIN_LIST';
export const UPDATE_COIN_LIST_SUCCESS = 'UPDATE_COIN_LIST_SUCCESS';
export const UPDATE_COIN_LIST_FAILURE = 'UPDATE_COIN_LIST_FAILURE';

export const STORE_COIN_LIST = 'STORE_COIN_LIST';
export const STORE_COIN_LIST_SUCCESS = 'STORE_COIN_LIST_SUCCESS';
export const STORE_COIN_LIST_FAILURE = 'STORE_COIN_LIST_FAILURE';

export const GET_GLOBAL_RANKING_LIST = 'GET_GLOBAL_RANKING_LIST';
export const GET_GLOBAL_RANKING_LIST_SUCCESS =
  'GET_GLOBAL_RANKING_LIST_SUCCESS';
export const GET_GLOBAL_RANKING_LIST_FAILURE =
  'GET_GLOBAL_RANKING_LIST_FAILURE';

export const REFRESH_GLOBAL_RANKING_LIST = 'REFRESH_GLOBAL_RANKING_LIST';
export const REFRESH_GLOBAL_RANKING_LIST_SUCCESS =
  'REFRESH_GLOBAL_RANKING_LIST_SUCCESS';
export const REFRESH_GLOBAL_RANKING_LIST_FAILURE =
  'REFRESH_GLOBAL_RANKING_LIST_FAILURE';

export const RESTORE_TOKEN = 'RESTORE_TOKEN';
export const RESTORE_TOKEN_SUCCESS = 'RESTORE_TOKEN_SUCCESS';
export const RESTORE_TOKEN_FAILURE = 'RESTORE_TOKEN_FAILURE';

export const EMAIL = 'EMAIL';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAILURE = 'EMAIL_FAILURE';

export const SUCCESS_TOAST = 'SUCCESS_TOAST';
export const ERROR_TOAST = 'ERROR_TOAST';
export const INFO_TOAST = 'INFO_TOAST';
export const WARNING_TOAST = 'WARNING_TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';
