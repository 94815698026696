import {
  SUCCESS_TOAST,
  ERROR_TOAST,
  INFO_TOAST,
  WARNING_TOAST,
  CLEAR_TOAST,
} from '../action/types';

const INIT_STATE = {
  description: '',
  status: '',
};

const toastReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUCCESS_TOAST:
      return {
        ...state,
        description: action.payload,
        status: 'success',
      };
    case ERROR_TOAST:
      return {
        ...state,
        description: action.payload,
        status: 'error',
      };
    case INFO_TOAST:
      return {
        ...state,
        description: action.payload,
        status: 'info',
      };
    case WARNING_TOAST:
      return {
        ...state,
        description: action.payload,
        status: 'warning',
      };
    case CLEAR_TOAST:
      return {
        ...state,
        description: '',
        status: '',
      };

    default:
      return state;
  }
};

export default toastReducer;
