import React from 'react';
import { Container, Flex, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import Header from '../Header';
import TabComponent from '../TabComponent';
import tabs from '../Tabs';

const MotionBox = motion(Box);

const Layout = ({ children }) => {
  return (
    <>
      <Container
        maxW='container.lg'
        height='100%'
        display='flex'
        flexDirection='column'
        overflow='hidden'>
        <Flex align='center' justify='space-between' flex='1' py='1'>
          <Header heading='TopCoins.pro' />
        </Flex>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'tween',
            duration: 0.3,
            delay: 0.1,
          }}
          background='inhert'
          w='100%'
          display='flex'
          flexDirection='column'
          flexGrow='1'
          overflow='hidden'>
          {children}
        </MotionBox>
      </Container>
      {/* <Tabs
        colorScheme='red'
        w='100vw'
        isFitted
        variant='enclosed-colored'
        h='100%'>
        <TabList
          mb='1em'
          position='fixed'
          bottom='-14px'
          right='0'
          zIndex='2000'
          w='100vw'>
          <Tab borderRadius='md'>My Ranking</Tab>
          <Tab borderRadius='md'>Global Ranking</Tab>
        </TabList>
      </Tabs> */}
      <TabComponent tabs={tabs} />
    </>
  );
};

export default Layout;
