import { setIsSub } from '../../helper';
import { EMAIL, EMAIL_SUCCESS, EMAIL_FAILURE } from '../action/types';

const INIT_STATE = {
  loading: false,
};

const subscribeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMAIL:
      return { ...state, loading: true };
    case EMAIL_SUCCESS:
      setIsSub(true);
      return { ...state, loading: false };
    case EMAIL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default subscribeReducer;
