import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../utils';

const PublicRoute = ({ component: Component, ...restProps }) => {
  const [token, setToken] = React.useState(true);

  React.useEffect(() => {
    setToken(Boolean(getToken()));
  }, [setToken]);

  return (
    <Route
      {...restProps}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};

export default PublicRoute;
