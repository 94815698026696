import React from 'react';

const ModalDeleteIcon = ({ className, color }) => {
  return (
    <svg
      width='65'
      height='65'
      viewBox='0 0 65 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='32.5' cy='32.5' r='32.5' fill='#FFF9F9' />
      <path
        d='M44.6995 25.1547H21.3006C21.0475 25.1332 20.7967 25.2167 20.607 25.3854C20.4205 25.5642 20.3341 25.823 20.3758 26.0777L22.7804 46.9841C23.0624 49.2891 25.0318 51.0164 27.3584 50.9993H39.0115C41.4037 51.0333 43.4186 49.2234 43.6358 46.8457L45.6242 25.9854C45.6365 25.7616 45.5525 25.5433 45.393 25.3854C45.2034 25.2167 44.9526 25.1332 44.6995 25.1547ZM41.7862 46.7534C41.6409 48.1587 40.4261 49.2094 39.0116 49.1532H27.3585C25.9694 49.2095 24.7753 48.1794 24.6301 46.7995L22.318 27.0007H43.6821L41.7862 46.7534Z'
        fill='#D9291A'
      />
      <path
        d='M48.0751 19.1544H38.5491V17.6775C38.6004 16.251 37.4834 15.0532 36.0541 15.0019C36.0072 15.0003 35.9602 14.9998 35.9133 15.0007H30.0867C28.6567 14.9743 27.4761 16.1098 27.4497 17.5369C27.4488 17.5837 27.4492 17.6306 27.4509 17.6775V19.1543H17.9249C17.4141 19.1543 17 19.5675 17 20.0773C17 20.5871 17.4141 21.0003 17.9249 21.0003H48.0751C48.5859 21.0003 49 20.5871 49 20.0773C49 19.5675 48.5859 19.1544 48.0751 19.1544ZM36.6994 17.6775V19.1543H29.3005V17.6775C29.2484 17.273 29.5347 16.9029 29.94 16.8509C29.9886 16.8446 30.0377 16.8433 30.0866 16.8467H35.9132C36.3208 16.8179 36.6747 17.1243 36.7035 17.5312C36.7071 17.5799 36.7057 17.629 36.6994 17.6775Z'
        fill='#D9291A'
      />
      <path d='M45 26H21L25 50H41.5L45 26Z' fill='#D9291A' />
      <path d='M28 32H38' stroke='white' strokeLinecap='round' />
      <path d='M25 38H41' stroke='white' strokeLinecap='round' />
      <path d='M28 44H38' stroke='white' strokeLinecap='round' />
    </svg>
  );
};

export default ModalDeleteIcon;
