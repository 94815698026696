import { combineReducers } from 'redux';

import RankList from './rankListReducer';
import Search from './searchReducer';
import Toast from './toastReducer';
import Subscibe from './subsribeReducer';

const appReducer = combineReducers({
  RankList,
  Search,
  Toast,
  Subscibe,
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
