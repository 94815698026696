import React from 'react';
import {
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Container,
  InputRightElement,
  CloseButton,
  useColorModeValue,
  IconButton,
  Text,
  Flex,
  Center,
  Spacer,
} from '@chakra-ui/react';
import { RiSearchLine } from 'react-icons/ri';
import { BsArrowUpRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  getUserCoinsList,
  searching,
  isDisplayOpen,
  fetchCoinList,
  addCoin,
  updateUserCoinList,
} from '../../utils/redux/action';
import { alphabetically } from '../../utils';

const Search = () => {
  const [options, setOptions] = React.useState([]);
  const [cursor, setCursor] = React.useState(0);
  const [dbValue, saveToDb] = React.useState('');

  const closeColor = useColorModeValue('black.500', 'white.500');
  const searchBg = useColorModeValue('white', 'gray.800');
  const coinBg = useColorModeValue('#F8F8F8', '#373738');

  const dispatch = useDispatch();

  const displayOpen = useSelector((state) => state.Search.isDisplayOpen);
  const userCoinList = useSelector((state) => state.RankList.userCoinsList);
  const loading = useSelector((state) => state.RankList.loading);

  const searchLoading = useSelector((state) => state.Search.searchLoading);
  const search = useSelector((state) => state.Search.search);
  const searchCoinList = useSelector((state) => state.Search.searchCoinList);

  // console.log({ loading, userCoins });

  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(fetchCoinList());
  }, [dispatch]);

  React.useEffect(() => {
    if (dbValue === '' || search === '') {
      const sortByRanking = [...searchCoinList.sort(alphabetically(true))];
      let userIds = new Set(userCoinList.map(({ id }) => id));
      let selectedRows = sortByRanking.filter(({ id }) => !userIds.has(id));

      setOptions(selectedRows.slice(0, 10));
    } else {
      const sortedCoins = [
        ...searchCoinList.sort((a, b) => {
          let fa = a.id.toLowerCase(),
            fb = b.id.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }),
      ];

      // let ids = new Set(sortedCoins.map(({ id }) => id));
      // // console.log({ ids });
      // console.log(
      //   'sorted coins',
      //   sortedCoins
      //     .filter(
      //       ({ id, symbol }) =>
      //         [id, symbol]
      //           .join(' ')
      //           .toLowerCase()
      //           .indexOf(dbValue.toLowerCase()) > -1
      //     )
      //     .sort(alphabetically(true))
      // );
      // // let ids = new Set(
      // //   sortedCoins.filter(
      // //     ({ id, symbol }) =>
      // //       [id, symbol].join(' ').toLowerCase().indexOf(id.toLowerCase()) > -1
      // //   )
      // // );

      // const arrOfIds = [...ids];
      // const searchResult = searchBinary(`${dbValue}`, arrOfIds, true);
      // // console.log({ searchResult });

      // const serachResultSet = new Set(searchResult);
      // const finalArr = searchCoinList.filter(({ id }) =>
      //   serachResultSet.has(id)
      // );
      // const sortByRanking = [...finalArr.sort(alphabetically(true))];

      // let userIds = new Set(userCoinList.map(({ id }) => id));
      // let selectedRows = sortByRanking.filter(({ id }) => !userIds.has(id));
      // setOptions(selectedRows);
      let s = sortedCoins
        .filter(
          ({ id, symbol }) =>
            [id, symbol]
              .join(' ')
              .toLowerCase()
              .indexOf(dbValue.toLowerCase()) > -1
        )
        .sort(alphabetically(true));

      let userIds = new Set(userCoinList.map(({ id }) => id));
      let selectedRows = s.filter(({ id }) => !userIds.has(id)).slice(0, 20);
      setOptions(selectedRows);
      //setOptions(s);
    }
  }, [dbValue, searchCoinList, userCoinList, search]);

  React.useEffect(() => {
    dispatch(getUserCoinsList());
  }, [dispatch]);

  // eslint-disable-next-line
  const debouncedSave = React.useCallback(
    debounce((nextValue) => saveToDb(nextValue), 400),
    []
  );
  const handleChange = (e) => {
    const { value: nextValue } = e.target;
    dispatch(isDisplayOpen(true));
    dispatch(searching(nextValue));
    debouncedSave(nextValue);
  };

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      dispatch(isDisplayOpen(false));
    }
  };

  const handleAddCoin = (coin) => {
    dispatch(addCoin(coin));
    dispatch(updateUserCoinList(true));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor === 0) {
      return;
    } else if (
      e.keyCode === 40 &&
      cursor ===
        options.filter(({ id }) => id.indexOf(search.toLowerCase()) > -1)
          .length -
          1
    ) {
      return;
    } else if (e.keyCode === 38 && cursor > 0) {
      setCursor((prevState) => prevState - 1);
    } else if (
      (e.keyCode === 40 && cursor < search.length - 1) ||
      (cursor >= 0 && e.keyCode === 40)
    ) {
      setCursor((prevState) => prevState + 1);
    }
  };

  const setSearchOptions = async (option) => {
    dispatch(isDisplayOpen(false));
    dispatch(
      searching(
        String(option.id).charAt(0).toUpperCase() + String(option.id).slice(1)
      )
    );
  };

  return (
    <React.Fragment>
      <Box width='100%' mt='2' zIndex='3000'>
        <Container centerContent px='0' ref={wrapperRef} position='relative'>
          <InputGroup
            maxWidth='100%'
            size='lg'
            boxShadow='md'
            borderRadius='md'
            onClick={() => dispatch(isDisplayOpen(!displayOpen))}>
            <InputLeftElement
              pointerEvents={search ? 'auto' : 'none'}
              children={
                <IconButton
                  size='sm'
                  padding='0px'
                  fontSize='xl'
                  variant='ghost'
                  _focus={{ outline: 'none' }}
                  icon={<RiSearchLine color={closeColor} />}
                />
              }
            />
            <Input
              type='text'
              name='search'
              placeholder={
                searchLoading ? 'Fetching coins...' : 'Search for coins'
              }
              disabled={loading}
              fontWeight='300'
              value={search}
              autoCorrect='off'
              spellCheck='false'
              autoComplete='off'
              onClick={() => dispatch(isDisplayOpen(true))}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />

            {search && (
              <InputRightElement
                children={
                  <CloseButton
                    color={closeColor}
                    onClick={() => dispatch(searching(''))}
                  />
                }
                color={closeColor}
                cursor='auto'
              />
            )}
          </InputGroup>
          {displayOpen && (
            <>
              {options.length > 0 ? (
                <Box
                  width='100%'
                  mt='2'
                  borderWidth='1px'
                  borderStyle='solid'
                  borderRadius='md'
                  background={searchBg}
                  zIndex='30'
                  top='100%'
                  overflowY='auto'
                  position='absolute'
                  maxHeight='350px'>
                  {options.map((v, i) => {
                    return (
                      <div key={i}>
                        {i !== 0 && (
                          <Center>
                            <Box
                              width='94%'
                              borderTopColor={closeColor}
                              borderTopStyle='solid'
                              borderTopWidth='1px'
                            />
                          </Center>
                        )}
                        <Box
                          key={i}
                          width='100%'
                          onClick={() => {
                            setSearchOptions(v);
                            handleAddCoin(v);
                          }}
                          // borderRadius='md'
                          // borderStyle='solid'
                          // borderWidth={cursor === i ? '2px' : '0px'}
                          // borderColor={cursor === i ? 'blue.300' : 'none'}
                          py='2'
                          px='2'
                          _hover={{
                            borderRadius: 'md',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderColor: 'blue.300',
                          }}>
                          <Flex justify='flex-start' align='center'>
                            <Box
                              mx='3'
                              borderRadius='50%'
                              width='32px'
                              height='32px'
                              background={coinBg}
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              cursor='pointer'>
                              <LazyLoadImage
                                style={{
                                  borderRadius: '50%',
                                }}
                                alt={v.thumb}
                                height={30}
                                src={v.thumb} // use normal <img> attributes as props
                                width={30}
                                effect='black-and-white'
                              />
                            </Box>
                            <Box>
                              <Text
                                textTransform='capitalize'
                                fontSize='14px'
                                fontWeight='400'
                                pr='1'>
                                {v.id}
                              </Text>
                            </Box>
                            <Box>
                              <Text
                                textTransform='uppercase'
                                fontSize='14px'
                                fontWeight='400'>
                                ({v.symbol})
                              </Text>
                            </Box>
                            <Spacer />
                            <Box mx='4' my='2'>
                              <IconButton
                                aria-label='Search database'
                                size='sm'
                                variant='ghost'
                                icon={<BsArrowUpRight fontSize='20px' />}
                              />
                            </Box>
                          </Flex>
                        </Box>
                      </div>
                    );
                  })}
                </Box>
              ) : (
                <>
                  {dbValue.length > 0 && (
                    <Box
                      width='100%'
                      mt='2'
                      borderWidth='1px'
                      borderStyle='solid'
                      borderRadius='md'
                      background={searchBg}
                      zIndex='30'
                      top='100%'
                      overflowY='auto'
                      position='absolute'
                      height='100px'>
                      <Center mt='35px'>No Coins Found</Center>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(Search);
