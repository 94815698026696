// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { FONT_FAMILY } from '../constants';

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true,
    cssVarPrefix: 'top-coins',
  },
  colors: {
    gray: {
      800: '#1f2326',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('whiteAlpha.900', 'gray.800')(props),
      },
    }),
  },
  fonts: {
    body: [FONT_FAMILY].join(','),
  },
  letterSpacings: {
    normal: '0.025em',
  },
});
