import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import HomeComponent from '../../components/Home';
import { companyName } from '../../constants';
import RankList from '../RankList';

const Home = () => {
  // fetch users coins on first render using useEffect

  const userCoinList = useSelector((state) => state.RankList.userCoinsList);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{companyName}</title>
      </Helmet>
      {userCoinList.length > 0 ? <RankList /> : <HomeComponent />}
    </React.Fragment>
  );
};

export default Home;
