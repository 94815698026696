import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Provider as ReduxProvider } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';
import PublicRoute from './hocs/PublicRoutes';
import { PublicRoutes } from './constants/routes';
import { theme } from './utils';
import RootView from './pages';
import Loader from './components/Loader';
import store from './utils/redux/store';
import Toast from './components/Toast';

ReactDOM.render(
  <React.Fragment>
    <ReduxProvider store={store()}>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <Toast />
        <React.Suspense fallback={<Loader />}>
          <Router>
            <AnimatePresence exitBeforeEnter>
              <Switch>
                {PublicRoutes.map((route, index) => {
                  return (
                    <PublicRoute
                      key={index}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                    />
                  );
                })}
                <RootView />
              </Switch>
            </AnimatePresence>
          </Router>
        </React.Suspense>
      </ChakraProvider>
    </ReduxProvider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
