import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import Logo from '../../assets/images/Logo';

const Loader = ({ children }) => {
  return (
    <React.Fragment>
      <Flex
        justify='center'
        align='center'
        height='100vh'
        flexDirection='column'>
        <Box>
          <Logo />
        </Box>
        <Box mt='4'>
          <Text textAlign='center' fontWeight='300' fontSize='24px'>
            TopCoins.pro
          </Text>
        </Box>
        <Box mt='4'>
          <Text textAlign='center' fontWeight='300' fontSize='20px'>
            Loading...
          </Text>
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default Loader;
