import React from 'react';
import { TiHome } from 'react-icons/ti';
import { VscGlobe } from 'react-icons/vsc';

// Configure our tabs and tab content here

const tabs = [
  {
    title: 'Home',
    id: 'Ranking',
    icon: <TiHome fill='#e53e3e' />,
    color: '#e53e3e',
    path: '/',
  },
  {
    title: 'Global',
    id: 'globalranking',
    icon: <VscGlobe color='#67bb67' />,
    color: '#67bb67',
    path: '/global-rank-list',
  },
];

export default tabs;
