import React from 'react';

const HomePageImage = ({ className, color }) => {
  return (
    <svg
      width='301'
      height='278'
      viewBox='0 0 301 278'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 134H11' stroke='white' strokeLinecap='round' />
      <path d='M181 277H191' stroke='white' strokeLinecap='round' />
      <path d='M290 80H300' stroke='white' strokeLinecap='round' />
      <path d='M127 1H137' stroke='white' strokeLinecap='round' />
      <g opacity='0.4'>
        <rect x='31' y='37' width='210' height='50' rx='8' fill='#2C3134' />
        <circle opacity='0.2' cx='56' cy='62' r='13' fill='white' />
        <path
          opacity='0.2'
          d='M81 53H151'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          opacity='0.2'
          d='M81 71H221'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <rect x='71' y='122' width='210' height='50' rx='8' fill='#2C3134' />
        <circle opacity='0.2' cx='96' cy='147' r='13' fill='white' />
        <path
          opacity='0.2'
          d='M121 138H191'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          opacity='0.2'
          d='M121 156H261'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <rect x='31' y='207' width='210' height='50' rx='8' fill='#2C3134' />
        <circle opacity='0.2' cx='56' cy='232' r='13' fill='white' />
        <path
          opacity='0.2'
          d='M81 223H151'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          opacity='0.2'
          d='M81 241H221'
          stroke='white'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
      <circle cx='153' cy='144' r='40' fill='white' />
      <path
        d='M189 180L199 190'
        stroke='white'
        strokeWidth='4'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default HomePageImage;
